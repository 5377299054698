import Button from "../button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

const swiperSettings = {
  slidesPerView: 1,
  spaceBetween: 0,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: "auto",
      direction: "vertical",
      enabled: false,
    },
  },
};

export default function cbSteps_2({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame frame--justify-md-center pb-20">
          <div className="bit-xxs-12 bit-md-9">
            {block.title && (
              <div
                className="cb--steps_2__title text--align-center pb-10 pb-md-20"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="text--align-center text--weight-normal"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
          </div>
        </div>
        <div className="frame frame--justify-md-center">
          <div className="bit-xxs-12 bit-md-11 bit-lg-9">
            <div className="cb--steps_2__slider mb-50">
              <Swiper
                modules={[Pagination]}
                spaceBetween={swiperSettings.spaceBetween}
                slidesPerView={swiperSettings.slidesPerView}
                breakpoints={swiperSettings.breakpoints}
                pagination={{ clickable: true }}
              >
                {block.steps.length &&
                  block.steps.map((step) => {
                    return (
                      <SwiperSlide key={step.label}>
                        <div className="cb--steps_2__step">
                          <span className="cb--steps_2__step__label">
                            {step.label}
                          </span>
                          <div className="cb--steps_2__step__box">
                            {step.title && (
                              <p className="cb--steps_2__step__title">
                                {step.title}
                              </p>
                            )}
                            {step.text && (
                              <div
                                className="cb--steps_2__step__text"
                                dangerouslySetInnerHTML={{ __html: step.text }}
                              />
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="flex flex--row flex--wrap flex--align-center flex--justify-center">
          {block.button && (
            <Button button={block.button} classes="button button--gray" />
          )}
          {block.link && (
            <Button
              button={block.link}
              classes="button button--link mt-30 mt-sm-0 ml-sm-30"
            />
          )}
        </div>
      </div>
    </>
  );
}
