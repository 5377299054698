import ResponsiveImage from "../modules/responsive-image";
import classNames from "../../lib/classNames";

export default function CbTwoBoxesWithTextAndImage({block}) {
    return (
        <>
            {block.image && <figure className="tw-aspect-[16/8] tw-overflow-hidden mb-20 lg:mb-0">
                <ResponsiveImage image={block.image} classes="tw-object-cover tw-object-center tw-w-full tw-h-full"/>
            </figure>}

            <div className="container">
                <div className="tw-flex tw-flex-wrap tw-gap-y-10 lg:-tw-mt-[200px] tw-relative lg:tw-justify-between lg:tw-items-stretch">
                    {block.boxes.map((box, i) => {
                        return (
                            <div key={`box-${i}`} className={`tw-w-full ${block.boxes.length === 2 ? 'lg:tw-w-[48%]' : ''}`}>                                <div
                                    className="tw-bg-white tw-rounded-3xl tw-p-12 md:tw-py-16 md:tw-px-20 tw-shadow-lg lg:tw-shadow-xl tw-h-full">
                                    {box.title && <div
                                        className="[&>*]:tw-text-[32px] [&>*]:md:tw-text-[40px] [&>*]:tw-font-normal tw-pb-4 lg:tw-pb-6"
                                        dangerouslySetInnerHTML={{__html: box.title}}></div>}
                                    <div className={classNames(
                                        "tw-flex tw-flex-col",
                                        box.align_image_under ? 'tw-gap-8' : 'lg:tw-items-end tw-gap-16 md:tw-flex-row'
                                    )}>
                                        {box.text && <div className="tw-w-full"
                                                          dangerouslySetInnerHTML={{__html: box.text}}></div>}
                                        {box.image &&
                                            <figure className={classNames(
                                                "tw-w-4/5",
                                                box.align_image_under ? 'md:tw-w-3/5 lg:tw-mx-auto' : 'md:tw-w-full'
                                            )}><ResponsiveImage image={box.image}/></figure>}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}
