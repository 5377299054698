import Breadcrumbs from "../breadcrumbs";
import Button from "../button";

export default function CbBanner5({block}) {
    return (
        <>
            <div className="container pt-20 pt-md-30 text--align-center">
                <div className="frame frame--justify-sm-center">
                    <div className="bit-xxs-12 bit-sm-11 bit-md-10">
                        <div className="pb-20">
                            <Breadcrumbs/>
                        </div>
                        {block.title && (
                            <div
                                className="cb--banner_5__title"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}
                        {block.subtitle && (
                            <div
                                className="cb--banner_5__subtitle pb-30"
                                dangerouslySetInnerHTML={{__html: block.subtitle}}
                            />
                        )}
                        {block.text && (
                            <div
                                className="text--weight-normal pb-50"
                                dangerouslySetInnerHTML={{__html: block.text}}
                            />
                        )}
                        <div className="flex flex--row flex--wrap flex--align-center flex--justify-center">
                            {block.button && (
                                <Button button={block.button}
                                        classes={`button ${block.settings.background_color === 'blue' ? 'button--white-with-blue-text' : 'button--gray'}`}/>
                            )}
                            {block.link && (
                                <Button
                                    button={block.link}
                                    classes="button button--link mt-30 mt-sm-0 ml-sm-30"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
