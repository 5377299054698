import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";
import Rating from "../rating";
import Breadcrumbs from "../breadcrumbs";

export default function CbBanner7({block}) {
    return (
        <>
            <figure className="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-overflow-hidden">
                {block.background_image && (<ResponsiveImage
                    image={block.background_image}
                    layout="fill"
                    classes="tw-object-cover tw-w-full tw-h-full"
                />)}
            </figure>
            <div className="tw-relative">
                <div className="container tw-py-32">

                    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-8">

                        {block.title && (
                            <div className="tw-w-full lg:tw-w-1/2">
                                <div className="tw-text-white pb-10">
                                    <Breadcrumbs html="breadcrumbs here"/>
                                </div>
                                <div
                                    className="cb--banner_7__title"
                                    dangerouslySetInnerHTML={{__html: block.title}}
                                />
                            </div>
                        )}

                        <div className="tw-w-full lg:tw-w-5/12 lg:tw-px-10 lg:tw-ml-auto">
                            <div
                                className="tw-bg-white/90 tw-text-black tw-rounded-3xl tw-p-12 lg:tw-p-16 tw-shadow-xl">

                                {block.box.title && (<div className="cb--banner_7__box__title tw-pb-2"
                                                          dangerouslySetInnerHTML={{__html: block.box.title}}></div>)}
                                {block.box.rating && <div className="tw-pb-10">
                                    <Rating rating={block.box.rating}/>
                                </div>}

                                <div className="tw-pb-8">
                                    {block.box.usps && block.box.usps.length && block.box.usps.map((usp) => {
                                        return (<div className="flex" key={usp.text}>
                                            <i className="fas fa-check-circle mt-5 mr-10 !tw-text-green"/>
                                            <p className="pb-10">{usp.text}</p>
                                        </div>);
                                    })}
                                </div>

                                {block.box.button && <Button button={block.box.button} classes='button button--green'/>}

                                {block.box.text_under_button && (
                                    <p className="text--topline pb-0 pt-20 tw-text-green">
                                        {block.box.text_under_button}
                                    </p>)}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
