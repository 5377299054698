import {useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
// import Annotation from 'chartjs-plugin-annotation';
import {Bar} from 'react-chartjs-2';
import moment from "moment";
import 'moment/locale/nl';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    // Annotation,
);

ChartJS.defaults.font.family = 'Ubuntu';
ChartJS.defaults.color = 'black';

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
            }
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    let label = context.dataset.shortLabel || '';

                    if (label) {
                        label += ': ';
                    }

                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(context.parsed.y);
                    }
                    return label;
                }
            }
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function (value, index, ticks) {
                    return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(value);
                }
            },
        },
        x: {
            grid: {
                display: false,
            },
        }
    },
};

const getISOString = date => {
    date.set('hour', 0);
    date.set('minute', 0);
    date.set('second', 0);
    date.set('millisecond', 0);

    return date.toISOString();
}

const getApiUrl = (startDate, endDate, timePeriod = 'day', type = 'electricity') => {
    let url = process.env.NEXT_PUBLIC_CORS_PROXY_URL

    // not sure why, but it works
    endDate = endDate.clone().add(1, 'day')

    let start = getISOString(startDate)
    let end = getISOString(endDate)

    let grouping = 'grouping='
    let energyType = 'getapxtariffs'

    if (timePeriod === 'week') {
        grouping = 'grouping%5Btype%5D=ByDay&grouping%5BtimeZoneId%5D=W.%20Europe%20Standard%20Time'
    } else if (timePeriod === 'month') {
        grouping = 'grouping%5Btype%5D=ByDay&grouping%5BtimeZoneId%5D=W.%20Europe%20Standard%20Time'
    } else if (timePeriod === 'year') {
        grouping = 'grouping%5Btype%5D=ByMonth&grouping%5BtimeZoneId%5D=W.%20Europe%20Standard%20Time'
    }

    if (type === 'gas') {
        energyType = 'getlebatariffs'
    }

    return `${url}https://mijn.easyenergy.com/nl/api/tariff/${energyType}?startTimestamp=${start}&endTimestamp=${end}&${grouping}&includeVat=false`;
}

function getBarLabel(timestamp, timePeriod) {
    let t = moment(timestamp)

    // const currentDate = moment();
    // const weekStart = currentDate.clone().startOf('week');
    // const weekEnd = currentDate.clone().endOf('week');
    //
    // let days = [];
    // for (let i = 0; i <= 6; i++) {
    //     days.push(moment(weekStart).add(i, 'days').format("D MMM"));
    // }
    // console.log(days);

    if (timePeriod === 'day') {
        return t.format('HH:mm')
    } else if (timePeriod === 'week' || timePeriod === 'month') {
        return t.format('D MMM')
    } else if (timePeriod === 'year') {
        return t.format('MMM')
    }

    return timestamp;
}

const data = {
    energyTypes: [
        {
            id: 'energy-type-electricity',
            name: 'electricity',
            title: 'Stroom',
            chartLabel: 'Prijs voor stroom per uur (kWh)',
            averageLabel: 'Gemiddelde inkoopprijs voor stroom',
        },
        {
            id: 'energy-type-gas',
            name: 'gas',
            title: 'Gas',
            chartLabel: 'Prijs voor gas per uur (m3)',
            averageLabel: 'Gemiddelde inkoopprijs voor gas',
        },
    ],
    timePeriods: [
        {
            id: 'time-period-day',
            name: 'day',
            title: 'Dag',
        },
        {
            id: 'time-period-week',
            name: 'week',
            title: 'Week',
        },
        {
            id: 'time-period-month',
            name: 'month',
            title: 'Maand',
        },
        {
            id: 'time-period-year',
            name: 'year',
            title: 'Jaar',
        }
    ]
}

export default function EnergyPricesChart() {
    const today = moment().locale('nl')

    const [startDate, setStartDate] = useState(today.clone());
    const [endDate, setEndDate] = useState(today.clone());
    const [pricesData, setPricesData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [energyType, setEnergyType] = useState('electricity'); // electricity, gas
    const [timePeriod, setTimePeriod] = useState('day'); // day, week, month, year

    const [timePeriodLabel, setTimePeriodLabel] = useState('')
    const [chartLabel, setChartLabel] = useState('');
    const [shortChartLabel, setShortChartLabel] = useState('');
    const [barLabels, setBarLabels] = useState([]); // hour, date, month

    useEffect(() => {
        if (timePeriod === 'day') {
            setStartDate(today.clone())
            setEndDate(today.clone())
        } else if (timePeriod === 'week') {
            setStartDate(today.clone().startOf('isoWeek'))
            setEndDate(today.clone().endOf('isoWeek'))
        } else if (timePeriod === 'month') {
            setStartDate(today.clone().startOf('month'))
            setEndDate(today.clone().endOf('month'))
        } else if (timePeriod === 'year') {
            setStartDate(today.clone().startOf('year'))
            setEndDate(today.clone().endOf('year'))
        }
    }, [timePeriod]);

    useEffect(() => {
        setIsLoading(true)

        fetch(getApiUrl(startDate, endDate, timePeriod, energyType))
            .then((response) => response.text())
            .then((textResponse) => {
                setPricesData(JSON.parse(textResponse))
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [startDate, endDate, energyType])

    useEffect(() => {
        if (pricesData) {
            setChartLabel(data.energyTypes.find(e => e.name === energyType).chartLabel)
            setShortChartLabel(data.energyTypes.find(e => e.name === energyType).title)
            setBarLabels(pricesData.map(p => getBarLabel(p.Timestamp, timePeriod)));
        }
    }, [pricesData]);

    useEffect(() => {
        if (timePeriod === 'day') {
            setTimePeriodLabel(endDate.clone().format('LL'))
        } else if (timePeriod === 'week') {
            const start = startDate.clone().format('D MMMM')
            const end = endDate.clone().format('D MMMM')

            setTimePeriodLabel(start + ' - ' + end)
        } else if (timePeriod === 'month') {
            setTimePeriodLabel(startDate.clone().format('MMMM YYYY'))
        } else if (timePeriod === 'year') {
            setTimePeriodLabel(startDate.clone().format('YYYY'))
        }
    }, [endDate, timePeriod])

    if (isLoading && !pricesData) {
        return (
            <div className="position--relative" style={{minHeight: '50vh', width: '100%'}}>
                <div className="energy-prices-chart__spinner"><i className="fas fa-circle-notch fa-spin"></i></div>
            </div>
        )
    }

    if (!pricesData) {
        return <p className="text--align-center text--weight-bold">
            Sorry, er ging iets fout bij het laden van de energieprijzen.</p>
    }

    const chartData = {
        labels: barLabels,
        datasets: [
            {
                label: chartLabel,
                shortLabel: shortChartLabel,
                data: pricesData.map(p => p.TariffReturn),
                backgroundColor: '#6FCCD8',
                borderRadius: 100,
                categoryPercentage: 0.5,
            },
        ],
    };

    const nextClickHandler = () => {
        setStartDate(startDate.clone().add(1, timePeriod))
        setEndDate(endDate.clone().add(1, timePeriod))
    }

    const prevClickHandler = () => {
        if (['week', 'month', 'year'].includes(timePeriod)) {
            setStartDate(startDate.clone().subtract(1, timePeriod).startOf(timePeriod))
            setEndDate(endDate.clone().subtract(1, timePeriod).endOf(timePeriod))
        } else {
            setStartDate(startDate.clone().subtract(1, 'day'))
            setEndDate(endDate.clone().subtract(1, 'day'))
        }
    }

    return (
        <div className="energy-prices-chart">
            <div className="energy-prices-chart__buttons-wrapper">
                <ul className="energy-prices-chart__button-list energy-prices-chart__button-list--energy-type">
                    {data.energyTypes.map(e => (
                        <li>
                            <input type="radio" value={e.name} name="energyType" id={e.id}
                                   checked={energyType === e.name}
                                   onChange={() => setEnergyType(e.name)}/>
                            <label key={e.name} htmlFor={e.id}>
                                {e.title}
                            </label>
                        </li>
                    ))}
                </ul>
                <ul className="energy-prices-chart__button-list energy-prices-chart__button-list--time-period">
                    {data.timePeriods.map(t => {
                        return (
                            <li>
                                <input type="radio" value={t.name} name="timePeriod" id={t.id}
                                       checked={timePeriod === t.name}
                                       onChange={() => setTimePeriod(t.name)}/>
                                <label key={t.name} htmlFor={t.id}>
                                    {t.title}
                                </label>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="flex flex--justify-center flex--align-center pt-30 pb-30">
                <button className="energy-prices-chart__arrow" onClick={prevClickHandler}>
                    <i className="fas fa-arrow-left"/>
                </button>
                <p className="pl-20 pr-20 pb-0 text--large">{timePeriodLabel}</p>
                <button className="energy-prices-chart__arrow" onClick={nextClickHandler}>
                    <i className="fas fa-arrow-right"/>
                </button>
            </div>

            <div className="position--relative" style={{minHeight: '50vh', width: '100%'}}>
                {isLoading &&
                    <div className="energy-prices-chart__spinner"><i className="fas fa-circle-notch fa-spin"></i></div>}
                {
                    Array.isArray(pricesData) && pricesData.length === 0 ?
                        <p className="text--align-center text--weight-bold">
                            Momenteel is er nog geen gemeten data beschikbaar om weer te geven.
                        </p> :
                        <Bar options={chartOptions} data={chartData}/>
                }
            </div>

        </div>
    )
}