import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const swiperSettings = {
    slidesPerView: 3,
    spaceBetween: 0,
    breakpoints: {
        1024: {
            spaceBetween: 30,
        },
    },
};

export default function cbProducts2({block}) {
    return (
        <>
            <div className="container">
                <div className="frame frame--justify-sm-center">
                    <div className="bit-xxs-12">
                        {block.title && (
                            <div
                                className="text-center"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}

                        {block.text && (
                            <div
                                className="tw-text-center tw-max-w-[900px] tw-mx-auto tw-font-normal"
                                dangerouslySetInnerHTML={{__html: block.text}}/>
                        )}
                    </div>
                    <div className="bit-xxs-12">
                        <div className="cb--reviews_slider__slider">
                            <Swiper
                                modules={[Pagination]}
                                spaceBetween={swiperSettings.spaceBetween}
                                slidesPerView={swiperSettings.slidesPerView}
                                breakpoints={swiperSettings.breakpoints}
                                pagination={{clickable: true}}
                            >
                                {block.products && block.products.length &&
                                    block.products.map((product) => {
                                        return (
                                            <SwiperSlide key={product.title}>
                                                <div
                                                    className="tw-bg-white tw-border-[6px] tw-border-white tw-rounded-2xl">
                                                    {product.title && (
                                                        <p className="">{product.title}</p>
                                                    )}
                                                </div>
                                                {/*<div className="cb--reviews_slider__review">*/}
                                                {/*    <figure className="cb--reviews_slider__review__image">*/}
                                                {/*        {review.image && (*/}
                                                {/*            <ResponsiveImage image={review.image}/>*/}
                                                {/*        )}*/}
                                                {/*    </figure>*/}
                                                {/*    <div*/}
                                                {/*        className="cb--reviews_slider__review__card p-20 pt-30 pb-40 p-md-40 pb-md-50">*/}
                                                {/*        {review.name && (*/}
                                                {/*            <p className="pb-15">*/}
                                                {/*                <strong>{review.name}</strong>*/}
                                                {/*            </p>*/}
                                                {/*        )}*/}
                                                {/*        {review.title && (*/}
                                                {/*            <p className="h3 pb-20">{review.title}</p>*/}
                                                {/*        )}*/}
                                                {/*        {review.text && (*/}
                                                {/*            <div*/}
                                                {/*                className="text--italic pb-20"*/}
                                                {/*                dangerouslySetInnerHTML={{*/}
                                                {/*                    __html: review.text,*/}
                                                {/*                }}*/}
                                                {/*            />*/}
                                                {/*        )}*/}
                                                {/*        {review.link && (*/}
                                                {/*            <Button button={review.link} classes="button"/>*/}
                                                {/*        )}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
