import ResponsiveImage from "../modules/responsive-image";

export default function CbProducts({block}) {
    return (
        <>
            <div className="container">
                {block.products && block.products.length &&
                    block.products.map((product) => (
                        <div className="frame tw-align-center">
                            {product.image &&
                                <div className="bit-xxs-12 bit-sm-6 bit-lg-4">
                                    <div className="tw-aspect-square tw-rounded-3xl tw-overflow-hidden tw-shadow-md">
                                        <ResponsiveImage
                                            image={product.image}
                                            classes="tw-object-cover tw-w-full tw-h-full"
                                        />
                                    </div>
                                </div>
                            }
                            {product.title &&
                                <div className="bit-xxs-12 bit-sm-6 bit-lg-8">
                                    {product.title && (
                                        <div className="[&>*]:tw-text-[40px]"
                                             dangerouslySetInnerHTML={{__html: product.title}}></div>
                                    )}
                                    {product.text && (
                                        <div className="tw-pb-5"
                                             dangerouslySetInnerHTML={{__html: product.text}}></div>
                                    )}
                                    {product.usps && product.usps.length &&
                                        <div className="tw-flex tw-flex-col tw-gap-y-2 tw-pb-10">
                                            {product.usps.map((usp) => (
                                                <div className="tw-flex tw-items-center tw-gap-x-2">
                                                    <i className="far fa-check-circle shrink-0 tw-text-green"/>
                                                    <span key={usp.text}>{usp.text}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    ))
                }
            </div>
        </>
    );
}
