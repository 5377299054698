import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";

export default function CbImageAndText3({block}) {
    const contentContainerClasses = `tw-w-full tw-text-white ${
        block.image ? "md:tw-w-1/2 lg:tw-w-5/12" : "tw-text-center"
    }`;

    return (
        <>
            <div className="container">
                <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-gap-x-10 lg:tw-gap-x-32">
                    {block.image &&
                        <div className="tw-w-full md:tw-w-1/3 lg:tw-w-4/12 tw-pb-8">
                            <ResponsiveImage
                                image={block.image}
                                classes="tw-rounded-3xl"
                            />
                        </div>
                    }
                    <div className={contentContainerClasses}>
                        {block.title &&
                            <div className="tw-text-white tw-text-[48px] lg:tw-text-[75px] pb-10"
                                 dangerouslySetInnerHTML={{__html: block.title}}></div>}

                        {block.text && <div
                            className="tw-pb-10 tw-max-w-6xl tw-mx-auto"
                            dangerouslySetInnerHTML={{__html: block.text}}></div>}

                        {block.button && (<Button
                            button={block.button}
                            classes="button button--white-with-green-text"
                        />)}
                    </div>
                </div>
            </div>
        </>
    );
}
